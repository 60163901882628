import {fadeIn} from '@pernod-ricard-global-cms/jsanimations';

export default function siteHeaderJs(options = {}) {
	try {
		// CSS for site-header should usually be included in critical.scss
		const {block} = options;
		fadeIn(block);

		const documentBody = document.body;
		const navContainer = document.querySelector('.header-nav');
		var hashLinkID  = '';
		const hamburgerWrapper = document.querySelector(
			'.header-interactions__hamburger-wrapper'
		);
		const hamburger = document.querySelector(
			'.header-interactions__hamburger'
		);
		const hashLink = document.querySelectorAll(
			'a'
		);
		const hamburgerBars = hamburger.querySelectorAll(
			'.header-interactions__hamburger-bar'
		);
		const itemsWithChildren = navContainer.querySelectorAll(
			'a.menu-item-has-children'
		);

		const closeSubnavs = () => {
			navContainer
				.querySelectorAll('.header-nav__item.active')
				.forEach((el) => el.classList.remove('active'));
			documentBody.classList.remove('mobile-menu-active');
		};

		hamburgerWrapper.addEventListener('click', function () {
			if (screen.width < 1024) {
				hamburger.classList.toggle('active');
				hamburgerBars.forEach((item) => {
					item.classList.toggle('active');
				});
				documentBody.classList.toggle('mobile-menu-active');
			}
		});

		itemsWithChildren.forEach((item) => {
			item.addEventListener('click', function (e) {
				e.preventDefault();
				item.parentNode.classList.toggle('active');
			});
		});

		hashLink.forEach((link) => {		
			link.addEventListener('click', function(event) {
				if (link.href.indexOf('#') !== -1) {
				event.preventDefault();
				const targetElement = document.querySelector(link.hash);
				const targetOffsetTop = targetElement.offsetTop;
				hashLinkID = link.hash;
				window.scrollTo({
					top: targetOffsetTop - 100,
					behavior: 'smooth'
				});
				}
			});
		});
		
		const detectScrolling = () => {
			if (
				window.scrollY > 10 &&
				!block.classList.contains('scrolling')
			) {
				block.classList.add('scrolling');
			} else if (
				window.scrollY < 10 &&
				block.classList.contains('scrolling')
			) {
				block.classList.remove('scrolling');
			}

			if( hashLinkID ){
				const targetElement = document.querySelector(hashLinkID);
				const targetOffsetTop = targetElement.offsetTop;

				if( window.scrollY > targetOffsetTop ){
					hashLinkID = '';
					window.scrollTo({
						top: targetOffsetTop - 100
					});
				}
			}
		};

		window.addEventListener('scroll', detectScrolling, false);
		window.addEventListener('resize', closeSubnavs, true);
	} catch (error) {
		console.error(error);
	}
}
